var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _vm.apirequestLoading
        ? _c("v-progress-linear", {
            attrs: { indeterminate: "", color: "cyan" },
          })
        : [
            _c(
              "v-list",
              {
                staticClass: "pa-0 mb-1",
                attrs: { color: "#f0f0f0", "min-height": "26px", dense: "" },
              },
              [
                _c(
                  "v-list-item",
                  { staticClass: "pa-0 px-1" },
                  [
                    _c("v-list-item-icon", { staticClass: "mr-0" }, [
                      _c("span", [_vm._v("#")]),
                    ]),
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", [
                          _vm._v(_vm._s(_vm.$t("message.mixed.checklistName"))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-action",
                      [
                        _c("v-list-item-title", [
                          _vm._v(_vm._s(_vm.$t("message.mixed.noOfDocuments"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-expansion-panels",
              { attrs: { multiple: "", focusable: "" } },
              _vm._l(_vm.listOfChecklistDocuments, function (checklist, i) {
                return _c(
                  "v-expansion-panel",
                  { key: i },
                  [
                    _c(
                      "v-expansion-panel-header",
                      {
                        staticClass: "pa-1",
                        staticStyle: { "min-height": "36px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "actions",
                              fn: function () {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        small: "",
                                        rounded: "",
                                        color: "primary",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            checklist.documents
                                              ? checklist.documents.length
                                              : 0
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { right: "", color: "white" },
                                        },
                                        [_vm._v("mdi-chevron-down")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c("span", { staticClass: "text-truncate" }, [
                          _c(
                            "strong",
                            {
                              staticStyle: {
                                float: "left: width:content-width",
                              },
                            },
                            [_vm._v(_vm._s(i + 1) + ".")]
                          ),
                          _vm._v(" " + _vm._s(checklist.checklist_name) + " "),
                        ]),
                      ]
                    ),
                    _c(
                      "v-expansion-panel-content",
                      { staticClass: "custom_content_wrap" },
                      [
                        _c(
                          "v-row",
                          { staticClass: "mx-0" },
                          [
                            _c(
                              "v-col",
                              { staticClass: "pa-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "v-simple-table",
                                  {
                                    staticClass: "dense_table bordered--table",
                                    attrs: { dense: "", width: "100%" },
                                  },
                                  [
                                    _c("thead", [
                                      _c(
                                        "tr",
                                        _vm._l(
                                          _vm.documentHeaders,
                                          function (documentHeader, index) {
                                            return _c("th", { key: index }, [
                                              _vm._v(
                                                _vm._s(
                                                  index === 0
                                                    ? documentHeader.text
                                                    : _vm.$t(
                                                        documentHeader.text
                                                      )
                                                )
                                              ),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        checklist.documents,
                                        function (document, i) {
                                          return _c("tr", { key: i }, [
                                            _c("td", [_vm._v(_vm._s(i + 1))]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  document.file_original_name
                                                )
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      "x-small": "",
                                                      fab: "",
                                                      color: "primary",
                                                      text: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: { size: "24" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.downloadDocument(
                                                              document._id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("mdi-download")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }